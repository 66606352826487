import { gql } from "graphql-tag";

export const HOME_SCREEN_FIELDS = gql`
  fragment HomeScreenFields on HomeScreen {
    id
    label
    title
    path
    description
    button_link
    button_text
    background_image
    status
    position
    image {
      id
      file_path
    }
  }
`;

export default { HOME_SCREEN_FIELDS };
