
import { Modal } from 'bootstrap';
import { defineComponent, ref, inject, watchEffect } from 'vue';
import { Input, Image } from '@/components/input-elements';
import { useStore } from 'vuex';
import { HomeScreenAddFormData } from '../interfaces/index';
import { Apollo, Notify } from '@/core/services';
import { GET_HOME_SCREENS } from '../graphql/Queries';
import { CREATE_HOME_SCREEN } from '../graphql/Mutations';
import { Actions } from '@/store/enums/StoreEnums';
import { GET_LOCALES } from '@/modules/common/locales/graphql/Queries';
import { useI18n } from 'vue-i18n';
import InnerLoader from '@/components/InnerLoader.vue';

export default defineComponent({
    name: 'new-category-modal',
    components: {
        Input,
        Image,
        InnerLoader
    },
    setup() {
        const emitter: any = inject('emitter');
        const loading = ref(false);
        const locales = ref([]) as Record<any, any>;
        const modal: any = ref();
        const tabsValue = ref('en');
        const tempValuesLocale = ref({}) as Record<any, any>;
        const tempValuesLabel = ref({}) as Record<any, any>;
        const tempValuesTitle = ref({}) as Record<any, any>;
        const tempValuesDescription = ref({}) as Record<any, any>;
        const tempValuesButtonText = ref({}) as Record<any, any>;
        const store = useStore();
        const disabled = ref(false);
        const set_sites = ref([]) as Record<any, any>;
        const addHomeScreenForm = ref<null | HTMLFormElement>(null);
        const categories = ref([]) as Record<any, any>;
        const previewImage = ref([]) as Record<any, any>;
        const i18n = useI18n();

        const addHomeScreenData = ref<HomeScreenAddFormData>({
            locale: 'en',
            label: '',
            title: '',
            description: '',
            button_link: '',
            button_text: '',
            background_image: false,
            status: false
        });

        const categoriesList = ref([]);

        watchEffect(() => {
            categoriesList.value.forEach((element: Record<any, any>) => {
                let defaultLocale = '';
                if (JSON.parse(element.name)[`${addHomeScreenData.value.locale}`] == undefined) {
                    defaultLocale = 'en';
                } else {
                    defaultLocale = addHomeScreenData.value.locale;
                }
                categories.value.push({
                    value: element.id,
                    label: JSON.parse(element.name)[`${defaultLocale}`]?.name
                });
            });
        });

        // On change image
        const handleChange = async (event: Record<any, any>) => {
            loading.value = true;
            const media = event.target.files;
            const response = await store.dispatch(Actions.CREATE_MEDIA, media);
            const images = JSON.parse(response);
            images.forEach(element => {
                previewImage.value.push({ src: element.asset_path, id: element.id });
            });
            loading.value = false;
        };

        // Remove Media Function
        const removeMedia = (index: number, media_id: number) => {
            store.getters.appInstance.$messageBox
                .confirm(`${i18n.t('message.ARE_YOU_SURE_TO_DELETE_RECORD')}?`, i18n.t('message.INFO'), {
                    confirmButtonText: i18n.t('message.OK'),
                    cancelButtonText: i18n.t('message.CANCEL'),
                    type: 'info'
                })
                .then(async () => {
                    loading.value = true;
                    const resp = await store.dispatch(Actions.REMOVE_MEDIA, media_id);
                    if (resp == 'true') {
                        previewImage.value.splice(index, 1);
                        Notify.success(i18n.t('message.MEDIA_REMOVED_SUCCESSFULLY'));
                    }
                    loading.value = false;
                })
                .catch(() => {
                    loading.value = false;
                });
        };

        // Show Media
        const showMedia = async (num: number) => {
            const formData = {
                previewImage: previewImage.value,
                visible: true,
                index: num
            };
            await store.dispatch(Actions.TRIGGER_ACTION, { formData: formData, type: 'imageProps' });
        };

        const loadLang = () => {
            const locales_data = Apollo.readQuery({
                query: GET_LOCALES
            });
            locales_data.locales.forEach(element => {
                locales.value.push({
                    label: element.locale.charAt(0).toUpperCase() + element.locale.slice(1),
                    value: element.locale
                });
                tempValuesLocale.value[element.locale] = element.locale;
                tempValuesLabel.value[element.locale] = { name: '' };
                tempValuesTitle.value[element.locale] = { name: '' };
                tempValuesDescription.value[element.locale] = { name: '' };
                tempValuesButtonText.value[element.locale] = { name: '' };
            });
        };

        loadLang();

        // On change input value
        const changeLang = (e: string) => {
            tabsValue.value = e;
            addHomeScreenData.value.label = tempValuesLabel.value[e].name;
            addHomeScreenData.value.title = tempValuesTitle.value[e].name;
            addHomeScreenData.value.description = tempValuesDescription.value[e].name;
            addHomeScreenData.value.button_text = tempValuesButtonText.value[e].name;
        };

        const updateLabelValue = (e: string) => {
            tempValuesLabel.value[addHomeScreenData.value.locale].name = e;
        };

        const updateTitleValue = (e: string) => {
            tempValuesTitle.value[addHomeScreenData.value.locale].name = e;
        };

        const updateButtonTextValue = (e: string) => {
            tempValuesButtonText.value[addHomeScreenData.value.locale].name = e;
        };

        // tab link press to ge locale
        const tabClickHandler = (tab: Record<any, any>) => {
            // addHomeScreenData.value.description = tempValuesDescription.value[tab.props.name].name;
            // addHomeScreenData.value.locale = tab.props.name;

            addHomeScreenData.value.locale = tab.props.name;
            addHomeScreenData.value.label = tempValuesLabel.value[tab.props.name].name;
            addHomeScreenData.value.title = tempValuesTitle.value[tab.props.name].name;
            addHomeScreenData.value.description = tempValuesDescription.value[tab.props.name].name;
            addHomeScreenData.value.button_text = tempValuesButtonText.value[tab.props.name].name;
        };

        // Update value on change description
        const updateValueDescription = (e: string) => {
            tempValuesDescription.value[addHomeScreenData.value.locale].name = e;
        };

        // Form submit Handler
        const submitHomeScreenForm = () => {
            for (const i in tempValuesLocale.value) {
                if (tempValuesDescription.value[i].name == '') {
                    tempValuesDescription.value[i].name = addHomeScreenData.value.description;
                }

                if (tempValuesLabel.value[i].name == '') {
                    tempValuesLabel.value[i].name = addHomeScreenData.value.label;
                }

                if (tempValuesTitle.value[i].name == '') {
                    tempValuesTitle.value[i].name = addHomeScreenData.value.title;
                }

                if (tempValuesButtonText.value[i].name == '') {
                    tempValuesButtonText.value[i].name = addHomeScreenData.value.button_text;
                }
            }
            if (
                addHomeScreenData.value.label == '' &&
                addHomeScreenData.value.title == '' &&
                addHomeScreenData.value.description == '' &&
                addHomeScreenData.value.button_text == '' &&
                addHomeScreenData.value.button_link == '' &&
                previewImage.value.length == 0
            ) {
                Notify.error(`${i18n.t('message.ALL_FIELDS_ARE_EMPTY')}`);
                return;
            }
            addHomeScreenForm.value?.validate(async (valid: boolean) => {
                if (valid) {
                    const formData = {
                        label: JSON.stringify(tempValuesLabel.value),
                        title: JSON.stringify(tempValuesTitle.value),
                        description: JSON.stringify(tempValuesDescription.value),
                        button_link: addHomeScreenData.value.button_link,
                        button_text: JSON.stringify(tempValuesButtonText.value),
                        background_image: addHomeScreenData.value.background_image,
                        status: addHomeScreenData.value.status,
                        image: previewImage.value.length != 0 ? previewImage.value[0].id : null
                    };
                    loading.value = true;
                    await Apollo.mutate({
                        mutation: CREATE_HOME_SCREEN,
                        variables: {
                            input: formData
                        },
                        update: (store, { data: { create_home_screen } }) => {
                            const read_data = store.readQuery({
                                query: GET_HOME_SCREENS
                            }) as Record<any, any>;
                            store.writeQuery({
                                query: GET_HOME_SCREENS,
                                data: {
                                    home_screens: [...read_data.home_screens, create_home_screen]
                                }
                            });
                            modal.value.hide();
                            loading.value = false;
                            Notify.success(`${i18n.t('message.RECORD_ADDED_SUCCESSFULLY')}`);
                        }
                    }).catch(() => {
                        loading.value = false;
                    });
                }
            });
        };

        const resetForm = () => {
            previewImage.value = [];
            addHomeScreenData.value.status = false;
            addHomeScreenData.value.background_image = false;
            emitter.emit('clearInput');
            tabsValue.value = 'en';
            for (const i of Object.getOwnPropertyNames(tempValuesLabel.value)) {
                tempValuesLabel.value[i].name = '';
                tempValuesTitle.value[i].name = '';
                tempValuesButtonText.value[i].name = '';
                tempValuesDescription.value[i].name = '';
            }
        };

        // Emitter for open modal
        emitter.on('addHomeScreenAction', () => {
            resetForm();
            addHomeScreenForm.value?.resetFields();
            modal.value = new Modal(document.getElementById('modal_add_home_screen'));
            modal.value.show();
        });

        return {
            set_sites,
            locales,
            addHomeScreenData,
            loading,
            tabsValue,
            disabled,
            categories,
            addHomeScreenForm,
            previewImage,
            showMedia,
            removeMedia,
            changeLang,
            updateLabelValue,
            updateTitleValue,
            updateButtonTextValue,
            handleChange,
            tabClickHandler,
            updateValueDescription,
            submitHomeScreenForm
        };
    }
});
