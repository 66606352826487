
import { defineComponent, ref, inject, watchEffect, onMounted, onUnmounted } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { Apollo, Notify } from '@/core/services';
import Search from '../../../../components/search/Search.vue';
import { GET_HOME_SCREENS, SEARCH_HOME_SCREENS } from '../graphql/Queries';
import { DELETE_HOME_SCREEN, UPDATE_SCREEN_POSITION } from '@/modules/system/home-screens/graphql/Mutations';
import draggable from 'vuedraggable';
import InnerLoader from '../../../../components/InnerLoader.vue';
import TableContentLoader from '@/components/Table/TableContentLoader.vue';
import { dropdown_handler } from '../../../../core/helpers/dropdownHandler';

export default defineComponent({
    name: 'payment-methods-list',
    components: { Search, draggable, InnerLoader, TableContentLoader },
    setup() {
        const store = useStore();
        const i18n = useI18n();
        const emitter: any = inject('emitter');
        const homeScreens = ref([]);
        const dataTable = ref([{}]);
        const systemLocale = ref();
        const drag = ref(false);
        const dbSortedCollection = ref([]);
        const loader = ref(false);
        const loading = ref(false);
        const activeIndex = ref(-1);
        const observable = ref();
        const searchObservable = ref();
        const search_screens_query = ref(false);

        const filterKeys = ref([
            {
                label: 'message.LABEL',
                value: 'label'
            },
            {
                label: 'message.TITLE',
                value: 'title'
            }
        ]);

        watchEffect(() => {
            systemLocale.value = i18n.fallbackLocale.value;
        });

        const getHomeScreens = () => {
            search_screens_query.value = false;
            searchObservable.value?.unsubscribe();
            loader.value = true;
            observable.value = Apollo.watchQuery({
                query: GET_HOME_SCREENS,
                fetchPolicy: 'network-only',
                nextFetchPolicy: 'cache-and-network',
                errorPolicy: 'all'
            }).subscribe(({ data, errors }) => {
                if (errors) {
                    loader.value = false;
                }
                homeScreens.value = data.home_screens;
                dbSortedCollection.value = data.home_screens;
                loader.value = false;
            });
        };

        const editHomeScreen = (home_screen: Record<any, any>) => {
            emitter.emit('editHomeScreenAction', home_screen);
        };

        const searchHandler = () => {
            search_screens_query.value = true;
            observable.value?.unsubscribe();
            const searchData = store.getters.getSearchData;
            loader.value = true;
            searchObservable.value = Apollo.watchQuery({
                query: SEARCH_HOME_SCREENS,
                errorPolicy: 'all',
                fetchPolicy: 'network-only',
                nextFetchPolicy: 'network-only',
                variables: {
                    filter: searchData.data.select,
                    search_key: searchData.data.input,
                    locale: systemLocale.value
                }
            }).subscribe(({ data, errors }) => {
                if (errors) {
                    loader.value = false;
                }
                homeScreens.value = data.search_home_screens;
                dbSortedCollection.value = data.home_screens;
                loader.value = false;
            });
        };

        const deleteHomeScreen = (id: number) => {
            store.getters.appInstance.$messageBox
                .confirm(`${i18n.t('message.ARE_YOU_SURE_TO_DELETE_RECORD')}?`, i18n.t('message.INFO'), {
                    confirmButtonText: i18n.t('message.OK'),
                    cancelButtonText: i18n.t('message.CANCEL'),
                    type: 'info'
                })
                .then(async () => {
                    loader.value = true;
                    await Apollo.mutate({
                        mutation: DELETE_HOME_SCREEN,
                        variables: { id: id },
                        update: (store, { data: { delete_home_screen } }) => {
                            // const read_data = store.readQuery({
                            // 	query: GET_HOME_SCREENS,
                            // }) as Record<any, any>;
                            // const data = read_data.home_screens.filter((t: any) => t.id !== delete_home_screen);
                            // store.writeQuery({
                            // 	query: GET_HOME_SCREENS,
                            // 	data: {
                            // 		home_screens: [...data],
                            // 	},
                            // });
                            search_screens_query.value == true ? searchHandler() : getHomeScreens();
                            loader.value = false;
                        }
                    });
                    Notify.success(i18n.t('message.RECORD_DELETED_SUCCESSFULLY'));
                })
                .catch(() => {
                    loader.value = false;
                });
        };

        const exportFile = data => {
            const searchData = store.getters.getSearchData;
            loading.value = true;
            const sub = Apollo.watchQuery({
                query: SEARCH_HOME_SCREENS,
                errorPolicy: 'all',
                fetchPolicy: 'network-only',
                variables: {
                    page: 0,
                    limit: 1,
                    filter: searchData?.data?.select ? searchData?.data?.select : '',
                    search_key: searchData?.data?.input ? searchData?.data?.input : '',
                    locale: systemLocale.value,
                    type: data?.type,
                    date_from: data?.dateFrom,
                    date_to: data?.dateTo
                }
            }).subscribe(({ data, errors }) => {
                if (errors) {
                    loading.value = false;
                } else if (data) {
                    window.open(data?.search_home_screens[0]?.path, '_blank');
                    loading.value = false;
                }

                loading.value = false;
                sub.unsubscribe();
            });
        };

        const rowPosition = event => {
            loader.value = true;
            const formData = {
                oldPosition: dbSortedCollection.value[event.moved.oldIndex]['position'],
                newPosition: dbSortedCollection.value[event.moved.newIndex]['position']
            };
            Apollo.mutate({
                mutation: UPDATE_SCREEN_POSITION,
                variables: {
                    input: formData
                },
                update: (store, { data: { update_screen_position } }) => {
                    if (update_screen_position == true) {
                        Notify.success(`${i18n.t('message.POSITION_CHANGED_SUCCESSFULLY')}`);
                        getHomeScreens();
                    }
                    loader.value = false;
                }
            }).catch(() => {
                loader.value = false;
            });
        };

        const dropdownHandler = (index, id) => {
            activeIndex.value = index === id ? null : id;
        };
        const handleClickOutside = event => {
            if (!event.target.closest('.action-btn')) {
                activeIndex.value = -1;
            }
        };

        onMounted(() => {
            getHomeScreens();
            document.addEventListener('click', handleClickOutside);
        });

        onUnmounted(() => {
            observable.value?.unsubscribe();
            searchObservable.value?.unsubscribe();
        });

        return {
            filterKeys,
            systemLocale,
            homeScreens,
            dataTable,
            drag,
            loading,
            loader,
            exportFile,
            rowPosition,
            searchHandler,
            getHomeScreens,
            editHomeScreen,
            deleteHomeScreen,
            dropdownHandler,
            handleClickOutside,
            activeIndex
        };
    }
});
